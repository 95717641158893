import React, { useState } from 'react'
import SiteMetadata from '../SiteMetadata'
import Phone from './Phone'
import ContactFormStyles from '../../styles/ContactForm.module.sass'

/**
 * Display a functional contact form
 *
 * @param {string} className
 * @param {string} buttonClass
 * @param {boolean} formOnly Toggles top info section | Default: false
 */
const ContactForm = props => {
  const { title } = SiteMetadata()

  const initialEmailSubjectString = 'EV Charger Enquiry'

  const [emailSubject, setEmailSubject] = useState(initialEmailSubjectString)

  const handleSetEmailSubject = (customerName) => {
    if (customerName && customerName.trim().length) {
      setEmailSubject(initialEmailSubjectString + ' from ' + customerName)
    }
    else {
      setEmailSubject(initialEmailSubjectString)
    }
  }

  return (
    <div
      className={`form-container ${ContactFormStyles.form} ${props.className ||
        ''}`}
    >
      <div>
        {(props.formOnly !== true || props.formOnly !== 'true') && (
          <>
            <span className={`title is-4 ${ContactFormStyles.title || ''}`}>
              Make an Enquiry
            </span>
            <p>
            We are happy to answer any questions and provide expert advice. Contact us using this form or call <Phone class={ContactFormStyles.phone} />.
            </p>
          </>
        )}

        <form
          name={`EV Charger Enquiry`}
          method="post"
          action="/thanks/"
          data-netlify="true"
          data-netlify-honeypot="first-name"
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value={`EV Charger Enquiry`} />
          <input type="hidden" name="subject" value={emailSubject} />
          <div hidden>
            <label>
              Trap for non-humans: <input name="first-name" />
            </label>
          </div>
          <div className="field">
            <div className="control">
              <label htmlFor="name">
                Your Name
              </label>
              <input
                className="input"
                type={'text'}
                name={'name'}
                id={'name'}
                required={true}
                onChange={e => handleSetEmailSubject(e.target.value)}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <label htmlFor="phone">
                Phone Number
              </label>
              <input
                className="input"
                type={'tel'}
                name={'phone'}
                id={'phone'}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <label htmlFor="email">
                Email
              </label>
              <input
                className="input"
                type={'email'}
                name={'email'}
                id={'email'}
                required={true}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <label htmlFor="message">
                Message
              </label>
              <textarea
                className="textarea"
                name={'message'}
                id={'message'}
                required={true}
                placeholder="If you are looking for a quote, please include your full address"
              />
            </div>
          </div>
          <div className="field">
            <button
              className={`button is-primary with-decoration-left ${
                ContactFormStyles.submit
              } ${props.buttonClass ? props.buttonClass : 'is-primary'}`}
              type="submit"
            >
              Send Enquiry
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ContactForm
