import React from 'react'
import { Link } from 'gatsby'
import Phone from './Phone'
import CTAStyles from '../../styles/CTA.module.sass'

/**
 * Display a call-to-action section
 *
 * @param {string} text Button label
 * @param {string} link Button link
 * @param {string} before Text above button
 * @param {bool} hidePhone
 * @param {string} class Element class
 */
const CTA = props => {
  return (
    <div className={`cta ${CTAStyles.cta} ${props.class || ''}`}>
      {props.before ? <em>{props.before}</em> : null}
      <Link
        to={props.link || '/contact/'}
        className={`button is-primary ${props.hidePhone !== "true" ? 'with-decoration-right' : null } ${CTAStyles.button || ''}`}
      >
        {props.text || 'Get a Quote'}
      </Link>
      { props.hidePhone !== "true" ? <Phone class={`button is-black with-decoration-left ${CTAStyles.phone}`} /> : null }
    </div>
  )
}

export default CTA
