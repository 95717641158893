import React from 'react'
import { Helmet } from 'react-helmet'
import { MDXProvider } from '@mdx-js/react'
import SiteMetadata from '../components/SiteMetadata'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import Cart from '../components/Cart'
import '../styles/_all.sass'

// Set up MDX Shortcodes
import Anchor from './shortcodes/Anchor'
import Button from './shortcodes/Button'
import ContactForm from './shortcodes/ContactForm'
import CTA from './shortcodes/CTA'
import Highlight from './shortcodes/Highlight'
import LargeCTA from './shortcodes/LargeCTA'
import PageContent from './shortcodes/PageContent'
import Phone from './shortcodes/Phone'
import Products from './shortcodes/Products'
import FauxTitle from './shortcodes/FauxTitle'

const shortcodes = {
  Anchor,
  Button,
  ContactForm,
  CTA,
  Highlight,
  LargeCTA,
  PageContent,
  Phone,
  Products,
  FauxTitle,
}

/**
 * Construct the global layout
 *
 * @param {string} [className]
 * @param {string} [showContactForm]
 */
const TemplateWrapper = props => {
  const { title, globalSchema } = SiteMetadata()

  return (
    <div>
      <Helmet>
        <html lang="en-gb" />
        <title>{props.seoTitle || props.title + ' | ' + title}</title>
        <meta name="description" content={props.seoDescription} />
        <script type="application/ld+json">{globalSchema}</script>
        <script type="application/ld+json">{props.seoSchema}</script>
      </Helmet>
      <Navbar />
      <div id="main" className={props.className}>
        <MDXProvider components={shortcodes}>{props.children}</MDXProvider>
        {/* <Cart floating={true} /> */}
      </div>
      <Footer showContactForm={props.showContactForm === false || props.showContactForm === "false" ? false : true} />
    </div>
  )
}

export default TemplateWrapper
