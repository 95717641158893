import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery, Link } from 'gatsby'
import Img from 'gatsby-image'
import ProductsStyles from '../../styles/Products.module.sass'

/**
 * Display a section with Products
 * @param {string} [className]
 */
const Products = props => {
  const data = useStaticQuery(posts)

  return (
    <div
      className={`container is-wider-than-parent ${ProductsStyles.products || ''} ${props.className || ''}`}
    >
      <div className="columns is-multiline is-centered">
        {data.allMdx.edges.map(edge => {
          return (
            <div
              key={edge.node.id}
              className={`column is-one-third-tablet ${ProductsStyles.product || ''}`}
            >
              <Link 
                to={`/${edge.node.fields.slug}`}
                className={ProductsStyles.header || ''}
              >
                <span className={ProductsStyles.label || ''}>{edge.node.frontmatter.label || 'Installation Included'}</span>
                <Img
                  className={ProductsStyles.image || ''}
                  fluid={edge.node.fields.image.childImageSharp?.fluid}
                />
                <strong className={ProductsStyles.title || ''}>
                  {edge.node.frontmatter.title}
                </strong>
              </Link>

              <ul className={ProductsStyles.highlights || ''}>
                {edge.node.frontmatter.highlights.map(highlight => {
                  return (
                    <li key={highlight.text}>
                      <i className={`${ProductsStyles.icon || ''} ${highlight.icon || ''}`}></i>
                      <span>{highlight.text}</span>
                    </li>
                  )
                })}
              </ul>

              <div className={ProductsStyles.prices || ''}>
                <div className={ProductsStyles.priceWithGrant || ''}>
                  <i>&euro;</i>
                  <span>{edge.node.frontmatter.priceWithGrant}</span>
                  <em>with SEAI Grant</em>
                </div>
                <div className={ProductsStyles.priceWithoutGrant || ''}>
                  <i>&euro;</i>
                  <span>{edge.node.frontmatter.priceWithoutGrant}</span>
                  <em>purchase price</em>
                </div>
              </div>

              <Link
                className={ProductsStyles.linkToGrant || ''}
                to="/ev-charger-grants"
              >
                <i className="icon-seai">SEAI - Sustainable Energy Authority of Ireland</i>
                <span>Grant Info</span>
              </Link>

              <Link 
                to={`/${edge.node.fields.slug}`}
                className={`button is-primary ${ProductsStyles.button || ''}`}>
                Specs &amp; Get a Quote
              </Link>

              { edge.node.frontmatter.schema ?
                <Helmet>
                  <script type="application/ld+json">
                    {edge.node.frontmatter.schema}
                  </script>
                </Helmet>
                : null 
              }
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Products

export const posts = graphql`
  {
    allMdx(filter: {frontmatter: {templateKey: {eq: "product"}}}, sort: {fields: frontmatter___productID, order: ASC}) {
      edges {
        node {
          id
          fields {
            slug
            image {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          frontmatter {
            highlights {
              icon
              text
            }
            priceWithGrant
            priceWithoutGrant
            productID
            schema
            title
            label
          }
        }
      }
    }
  }
`
