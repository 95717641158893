import React, { useState } from 'react'
import { Link } from 'gatsby'
import { get, list, quantity, total } from 'cart-localstorage'
import SiteMetadata from '../components/SiteMetadata'
import CartStyles from '../styles/Cart.module.sass'

const Cart = props => {
  const { maxProductQuantity } = SiteMetadata()

  const [cart, setCart] = useState( 
    typeof window !== 'undefined' 
    ? list()
    : null
  )

  // Prevent Gatsby from prerendering this page
  if (typeof window == 'undefined') {
    return false
  }

  // Increase/Decrease quantity
  function changeQty(operation, id) {
    if (operation === 'decrease') {
      quantity(id, -1)
    } else {
      if (get(id).quantity >= maxProductQuantity) {
        return false
      } else {
        quantity(id, 1)
      }
    }

    setCart(list())

    window.location.reload()
  }

  if (props.floating === true) {
    if (cart && cart.length) {
      return (
        <div className={`cart-widget ${CartStyles.floatingCart || ''}`}>
          <Link
            className={CartStyles.header || ''}
            to="/checkout"
          >
            <div>
              <b>My Order</b>
              <br />
              <i>&euro;{total()}</i>
            </div>
            <span
              className="button is-white"
            >
              View Order &amp; Pay
            </span>
          </Link>
        </div>
      )
    } else {
      return <div className={`cart-widget ${CartStyles.floatingCartEmpty}`}>Your cart is empty.</div>
    }
  }
  else {
    if (cart && cart.length) {
      return (
        <div className={CartStyles.cartFull || ''}>
          <table className={`table is-striped ${CartStyles.table || ''}`}>
            <thead>
              <tr>
                <td>Your Order</td>
                <td>Price</td>
                <td>Qty</td>
                <td className="is-hidden-mobile"></td>
              </tr>
            </thead>
            <tbody>
              {cart.map(item => {
                let productName = item.name.split(' - ')[0]
                let productOptions = item.name.split(' - ')[1]

                if (productOptions.includes(", ")) {
                  let productOptionsSplit = productOptions.split(", ")

                  productOptions = "<ul>"

                  productOptionsSplit.forEach(item => {
                    productOptions += "<li>" + item + "</li>"
                  })

                  productOptions += "</ul>"
                }
  
                return (
                  <tr key={item.id}>
                    <td>
                      <em>{productName}</em>
                      <span dangerouslySetInnerHTML={{__html: productOptions}} />
                    </td>
                    <td>&euro;{item.price}</td>
                    <td>
                      {item.quantity}
                      <div className="is-hidden-tablet">
                        <span
                          className={`tag ${CartStyles.increase || ''}`}
                          onClick={() => {
                            changeQty('increase', item.id)
                          }}
                          onKeyDown={() => {
                            changeQty('increase', item.id)
                          }}
                          role="button"
                          tabIndex="0"
                        >
                          +
                        </span>
                        <span
                          className={`tag ${CartStyles.decrease || ''}`}
                          onClick={() => {
                            changeQty('decrease', item.id)
                          }}
                          onKeyDown={() => {
                            changeQty('decrease', item.id)
                          }}
                          role="button"
                          tabIndex="0"
                        >
                          –
                        </span>
                      </div>
                    </td>
                    <td className="is-hidden-mobile">
                      <span
                        className={`tag ${CartStyles.increase || ''}`}
                        onClick={() => {
                          changeQty('increase', item.id)
                        }}
                        onKeyDown={() => {
                          changeQty('increase', item.id)
                        }}
                        role="button"
                        tabIndex="0"
                      >
                        +
                      </span>
                      <span
                        className={`tag ${CartStyles.decrease || ''}`}
                        onClick={() => {
                          changeQty('decrease', item.id)
                        }}
                        onKeyDown={() => {
                          changeQty('decrease', item.id)
                        }}
                        role="button"
                        tabIndex="0"
                      >
                        –
                      </span>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )
    }
    else {
      return false
    }
  }
}

export default Cart
