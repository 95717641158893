import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

/**
 * Display the body of any page
 * @param {string} slug
 */
const PageContent = props => {
  const data = useStaticQuery(posts)

  if (props.slug) {
    return (
      <>
        {data.allMdx.edges.map(edge => {
          console.log(edge)
  
          if (edge.node.fields.slug.replace('/', '') === props.slug) {
            return <MDXRenderer>{edge.node.body}</MDXRenderer>
          }
          else {
            return false
          }
        })}
      </>
    )
  }
  else {
    return false
  }
}

export default PageContent

export const posts = graphql`
  {
    allMdx(filter: {frontmatter: {templateKey: {eq: "page"}}}) {
      edges {
        node {
          id
          body
          fields {
            slug
          }
        }
      }
    }
  }
`
