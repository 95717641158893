import React from 'react'
import Img from 'gatsby-image'
import Button from './shortcodes/Button'
import HeroStyles from '../styles/Hero.module.sass'

/**
 * Display a custom hero image
 *
 * @param {boolean} special Default: false
 * @param {object} image
 * @param {string} imageAlt
 * @param {string} title
 * @param {string} subtitle
 * @param {boolean} showCta Default: true
 * @param {string=} ctaText
 * @param {string=} ctaUrl
 * @param {string=} ctaText2
 * @param {string=} ctaUrl2
 * @param {string} anchor
 */
const Hero = props => {
  return (
    <section
      className={`hero ${HeroStyles.hero} ${props.image ?
        'with-image' : ''} ${props.anchor || ''}`}
    >
      {props.image ? (
        props.image.childImageSharp ? (
          <div className={`hero-image ${HeroStyles.image || ''}`}>
            <Img
              fluid={props.image.childImageSharp.fluid}
              alt={props.imageAlt || ''}
              title={props.imageAlt || ''}
            />
          </div>
        ) : (
          <div className={`hero-image ${HeroStyles.image || ''}`}>
            <img
              src={props.image}
              alt={props.imageAlt || ''}
              title={props.imageAlt || ''}
            />
          </div>
        )
      ) : null}
      <div className={`hero-body ${HeroStyles.heroBody || ''}`}>
        <div className={`container ${HeroStyles.container || ''}`}>
          <h1
            className="title is-spaced is-1"
            dangerouslySetInnerHTML={{ __html: props.title }}
          />

          {props.subtitle && (
            <p
              className={`subtitle ${HeroStyles.subtitle || ''}`}
              dangerouslySetInnerHTML={{ __html: props.subtitle }}
            />
          )}

          {props.showCta !== false && props.showCta !== 'false' && (
            <>
              <Button
                url={props.ctaUrl || '/contact/'}
                text={props.ctaText || 'Get a Quote'}
                type={props.image && 'is-primary'}
              />
            </>
          )}

          {props.children}
        </div>
      </div>
    </section>
  )
}

export default Hero
