import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import Phone from './Phone'
import HighlightStyles from '../../styles/Highlight.module.sass'

/**
 * Display a content-filled Highlight section
 *
 * @param {string} title
 * @param {string} text
 * @param {string} buttonText Button label
 * @param {string} buttonURL
 * @param {string} imageURL
 * @param {boolean} hidePhone
 */
const Highlight = props => {
  return (
    <div className={HighlightStyles.highlight || ''}>
      <div className={`container ${HighlightStyles.container || ''}`}>
        <div className={HighlightStyles.content || ''}>
          <strong className={`title is-3 ${HighlightStyles.title || ''}`}>
            {props.title || ''}
          </strong>
          <p className={HighlightStyles.text}>
            {props.text || ''}
          </p>
          <div className={`buttons ${HighlightStyles.buttons || ''}`}>
            <Link to={props.buttonURL || '/contact/'} className="button is-primary with-decoration-left">
              {props.buttonText || 'Get a Quote'}
            </Link>
            {props.hidePhone !== 'true' && <Phone class={HighlightStyles.phone || ''} />}
          </div>
        </div>
        <div className={HighlightStyles.imageHolder || ''}>
          {props.image ? (
            props.image.childImageSharp ? (
              <Img
                fluid={props.image.childImageSharp.fluid}
                alt={props.title || ''}
                title={props.title || ''}
                className={HighlightStyles.image}
              />
            ) : (
              <img
                src={props.image}
                alt={props.title || ''}
                title={props.title || ''}
                className={HighlightStyles.image}
              />
            )
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default Highlight
